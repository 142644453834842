import React, { Component } from "react";
import { themeGet } from "styled-system";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, navigate } from "@reach/router";
import { authenticate } from "../../services/moocfi";
import { syncStore } from "../../state/appStore";
import { loggedIn } from '../../services/moocfi';

import StyledForm from "../StyledForm";
import { SmallContainer } from "../Grid";
import TextF2 from "../TextF2";
import TextF7 from "../TextF7";

import { currentLocale } from "../../services/locale";

const SignUpLink = styled.p`
  text-align: center;
  margin-top: 30px;
`;

const ForgotPasswordLink = styled.p`
  text-align: center;
  margin-top: 30px;
`;

const StyledF2 = styled(TextF2)`
  text-align: center;
  color: white;
`;

const StyledF7 = styled(TextF7)`
  text-align: center;
  color: white;
`;
const Error = styled.p`
  color: #ff7351;
`;

const SubmitButton = styled.button`
  font-size: 1rem;
  outline: none !important;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  line-height: "1";
  color: ${props =>
    props.disabled ? "rgba(255,255,255,0.5)" : "rgba(255,255,255,1)"};
  max-width: 280px;
  padding: 1rem;
  line-height: 2rem;
  border-radius: 2rem;
  background: #32c2a2;
  border: none;
  width: ${props => (props.disabled ? "80%" : "100%")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  transition: all 0.2s ease;
  margin: 0 auto;
  margin-top: 35px;
  display: block;
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.04);
  :hover {
    cursor: pointer;
  }
`;

//const Redirect = connect()(DisconnectedRedirect)

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value
});

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  redirect: null
};

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    if (loggedIn()) {
      const defaultPath =
        currentLocale() === "en" ? "/" : `/${currentLocale()}`;
      let passedReturnPath = null;

      if (typeof window !== "undefined") {
        const urlParams = new URLSearchParams(window.location.search);
        const returnTo = urlParams.get("return_to");
        if (returnTo && returnTo.indexOf("/") === 0) {
          passedReturnPath = returnTo;
        }
      }

      this.setState({
        redirect:
          passedReturnPath ||
          this.props.location.search.substring(1) ||
          defaultPath
      });
    }
  }
  onSubmit = event => {
    event.preventDefault();
    const { email, password } = this.state;

    authenticate({
      username: email,
      password
    })
      .then(() => {
        //!
        syncStore();

        const defaultPath =
          currentLocale() === "en" ? "/" : `/${currentLocale()}`;
        let passedReturnPath = null;

        if (typeof window !== "undefined") {
          const urlParams = new URLSearchParams(window.location.search);
          const returnTo = urlParams.get("return_to");
          if (returnTo && returnTo.indexOf("/") === 0) {
            passedReturnPath = returnTo;
          }
        }

        //!!!
        this.setState({
          redirect:
            passedReturnPath ||
            this.props.location.search.substring(1) ||
            defaultPath
        });
      })
      .catch(err => {
        this.setState({
          error: <FormattedMessage id="globals.invalidCredentials" /> //''Invalid credentials'
        });
      });
  };

  render() {
    const { email, password, error } = this.state;
    const { intl } = this.props;
    const isInvalid = password === "" || email === "";

    if (this.state.redirect) {
      navigate(this.state.redirect);
      return null;
    }

    return (
      <StyledForm error={error}>
        <SmallContainer pt={6}>
          <StyledF2 color="#fff">
            {" "}
            <FormattedMessage id="globals.signIn" />
          </StyledF2>
          <StyledF7>
            {" "}
            <FormattedMessage id="signIn.intro" />
          </StyledF7>
          <form onSubmit={this.onSubmit}>
            <label htmlFor="email">
              <FormattedMessage id="signIn.email" />
            </label>
            <input
              value={email}
              onChange={event =>
                this.setState(updateByPropertyName("email", event.target.value))
              }
              type="text"
              autoComplete="email"
            />
            <label htmlFor="password">
              <FormattedMessage id="signIn.password" />
            </label>
            <input
              value={password}
              onChange={event =>
                this.setState(
                  updateByPropertyName("password", event.target.value)
                )
              }
              type="password"
            />
            {error && <Error>{error}</Error>}
            <SubmitButton disabled={isInvalid} type="submit">
              <FormattedMessage id="globals.signIn" />
            </SubmitButton>
            <SignUpLink>
              <FormattedMessage id="signIn.noAccount" />
              &nbsp;
              <Link to={intl.formatMessage({ id: "routes.SIGN_UP" })}>
                <FormattedMessage id="signIn.signUpNow" />
              </Link>
            </SignUpLink>
            <SignUpLink />
          </form>
          <ForgotPasswordLink>
            <Link to={intl.formatMessage({ id: "routes.PASSWORD_FORGET" })}>
              <FormattedMessage id="globals.forgotPassword" />
            </Link>
          </ForgotPasswordLink>
        </SmallContainer>
      </StyledForm>
    );
  }
}

export default injectIntl(SignInForm);
